import React, { useState, useEffect } from "react";
import {useHistory, useLocation} from "react-router";
import styled from "styled-components";
import {Layout, Popconfirm, Popover, Switch, message} from "antd";
import { UserOutlined } from "@ant-design/icons";
import { privateRoutes } from "../routes/routes";
import styles from "../components/Top.less";
import { useLogout } from "../recoil/auth";
import useSWR from "swr";
import {TabBar, NavBar, Space} from "antd-mobile";
import useAxios from "../api/api";
import moment from "moment/moment";
const { Content } = Layout;

const ServiceWrapper = ({ children }) => {
    const history = useHistory();
    const logout = useLogout();
    const api = useAxios();
    const location = useLocation();
    const varUA = navigator.userAgent;
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem("token")

    const [isOpen, setIsOpen] = useState(0);
    const [isFirst, setIsFirst] = useState(true);

    const pathName = location.pathname;
    const [isVisibleProfilePopover, setIsVisibleProfilePopover] = useState(false);

    const fetchIsOpenData = async () => {
        try {
            const response = await api.get(`https://api.coffik.fifteenh.io/api/store/open`);
            const newData = response.data.data.is_open
            if (newData) {
                setIsOpen(newData);
            }
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        if (token) {
            fetchIsOpenData();
        }
    }, [token]);

    const findPath = privateRoutes.find(path => path.path === pathName);

    const setRouteActive = (value) => {
        history.push(value)
    }

    const handleSave = async (data) => {
        const formdata = new FormData();
        formdata.append("is_open", data);

        const {
            data: { success, alert, data: data2 },
        } = await api.post("/store/open", formdata);

        if (success) {
            setIsOpen(data)
        } else {
            message.error(alert);
        }
    };

    const fetchData = async (tempPage) => {
        try {
            const response = await api.get(`https://api.coffik.fifteenh.io/api/store/cafe/order?page=${tempPage}&status=0`);
            const newData = response.data.data.cafe_orders

            if (newData?.data.length > 0) {
                setIsFirst(false)

                if (varUA.match("coffikStore/Android") != null) {
                    window.coffikStore.postMessage(JSON.stringify({ type: "onAlarm" }));
                } else if (varUA.match("coffikStore/iOS") != null) {
                    window.webkit.messageHandlers.onAlarm.postMessage({});
                }

                setTimeout(() => {
                    if (varUA.match("coffikStore/Android") != null) {
                        window.coffikStore.postMessage(JSON.stringify({ type: "offAlarm" }));
                    } else if (varUA.match("coffikStore/iOS") != null) {
                        window.webkit.messageHandlers.offAlarm.postMessage({});
                    }
                }, 1000);
            }
        } catch (error) {
            console.log(error)
        }
    };

    const left = (
        <div>
            <Switch checked={isOpen === 1} onChange={() => {handleSave(isOpen === 1 ? 0 : 1)}} />
        </div>
    )

    const right = (
        <div style={{ fontSize: 24 }}>
            <Space style={{ '--gap': '16px' }}>
                <Popover
                    trigger="click"
                    placement="bottomRight"
                    overlayStyle={{ width: '200px' }}
                    content={
                        <>
                            <PopoverContents>
                                <div style={{ color: "black", cursor: "pointer", width: '100%' }} onClick={() => window.location.href = 'https://store.coffik.fifteenh.io'}>
                                    매장 관리자 바로가기
                                </div>
                                <Popconfirm

                                    placement="top"
                                    title="로그아웃 하시겠습니까?"
                                    onConfirm={logout}
                                    okText="확인"
                                    cancelText="취소"
                                >
                                  <span style={{ color: "black", cursor: "pointer", width: '100%' }}>
                                    로그아웃
                                  </span>
                                </Popconfirm>
                            </PopoverContents>
                        </>
                    }
                    visible={isVisibleProfilePopover}
                    onVisibleChange={(visibleState) =>
                        setIsVisibleProfilePopover(visibleState)
                    }
                >
                    < UserOutlined/>
                </Popover>
            </Space>
        </div>
    )

    return (
        <Layout
            style={{
                transition: "all 0.2s",
                minHeight: "100vh",
                backgroundColor: "white",
            }}
        >
            <div
                className={styles.top}
                 style={{
                     position: 'fixed', // 고정된 위치로 설정
                     top: 0, // 화면 하단으로 설정
                     left: 0,
                     right: 0,
                     backgroundColor: '#FFFFFF',
                     zIndex: 9999,
                 }}
            >
                <NavBar backArrow={false} right={right} left={left}><b>{findPath?.sidebar?.label ? findPath?.sidebar?.label : '주문 상세'}</b></NavBar>
            </div>
            <Content style={{ overflow: "initial", marginTop: 48, marginBottom: 50 }}>
                {children}
            </Content>
            <div
                style={{
                    position: 'fixed', // 고정된 위치로 설정
                    bottom: 0, // 화면 하단으로 설정
                    left: 0,
                    right: 0,
                    backgroundColor: '#FFFFFF',
                    zIndex: 9999,
                }}
            >
            <TabBar activeKey={pathName} onChange={value => setRouteActive(value)} style={{backgroundColor: "#FFFFFF"}}>
                {privateRoutes.map(item => (
                    Object(item.sidebar).label && (
                        <TabBar.Item key={item.path} icon={Object(item.sidebar).icon} title={<b>{Object(item.sidebar).label}</b>} />
                    )
                ))}
            </TabBar>
            </div>
        </Layout>
    );
};

const ProfileImage = styled.img`
  width: 25px;
  height: 25px;
  border-radius: 12.5px;
  cursor: pointer;
`;

const PopoverContents = styled.div`
  width: 150px;
`;

const LogoImage = styled.img`
  width: 100%;
  font-family: "Nanum Gothic", sans-serif;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  padding: 40px;
`;

export default ServiceWrapper;
